import React from 'react';
import styled from 'styled-components';
import bird2 from '../assets/images/birds/bird2.png';

const Page = styled.div`
  margin-top: 7.5vw;
  min-height: 50vh;
  header {
    h1 {
      font-size: 3em;
      padding-bottom: 30px;
    }
  }
  .siteWrapper {
    grid-column: 5 / span 16;
  }
`;

const Bird = styled.div`
  position: absolute;
  left: -70px;
  top: 100px;
  z-index: 0;
  width: 40vw;
  max-width: 400px;
  height: auto;
  opacity: 0.7;
  transform: translateY(-50%) rotate(0deg);
`;

export default function FourohFour() {
  return (
    <Page>
      <Bird>
        <img src={bird2} alt="" />
      </Bird>
      <div className="SiteContainer">
        <div className="siteWrapper">
          <header>
            <h1>404 page doesn't exist</h1>
          </header>
        </div>
      </div>
    </Page>
  );
}
